import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage, {
  AUTH_TOKEN_STORAGE_KEY,
  setAuthTokenForAllRequests,
} from './authentication/login_page';
import CmsPageContainer from './cms/components/cms_page_container';
import BytesPage from './cms/pages/bytes_page';
import ByteEditPage from './cms/pages/byte_edit_page';
import ExploreCategoriesPage from './cms/pages/explore_categories_page';
import ExploreCategoryGroupingsPage from './cms/pages/explore_category_groupings_page';
import CoachingPageContainer from './coaching/components/coaching_page_container';
import Checkins from './coaching/components/user_record/checkins';
import CompletedBytes from './coaching/components/user_record/completed_bytes';
import EwbQuizzes from './coaching/components/user_record/ewb_quizzes';
import Intentions from './coaching/components/user_record/intentions';
import Memos from './coaching/components/user_record/memos';
import RecommendedContent from './coaching/components/user_record/recommended_content';
import SessionNotes from './coaching/components/user_record/session_notes';
import CoachingAssignmentPage from './coaching/pages/coach_assignment_page';
import CoachingDashboardPage from './coaching/pages/coach_dashboard_page';
import UserRecordPage from './coaching/pages/user_record_page';
import CareTeam from './coaching/components/user_record/care_team';
import DemographicsAssessments from './coaching/components/user_record/demographics_assessments';
import CoachingUserMessageThreads from './coaching/components/user_record/coaching_user_messages_threads';
import ThreadDetails from './coaching/components/user_record/coach_messaging/thread_details';
import InternalPageContainer from './internal/components/internal_page_container';
import ChatBotThreadsPage from './internal/pages/chat_bot_threads_page';
import ChatBotThreadPage from './internal/pages/chat_bot_thread_page';
import ChatBotsPage from './internal/pages/chat_bots_page';
import PartnersPage from './internal/pages/partners_page';
import DassAssessments from './coaching/components/user_record/dass_assessments';
import UserDeletionPage from './internal/pages/user_deletion_page';
import Payments from './coaching/components/user_record/payments';
import UsersProgramBytes from './coaching/components/user_record/users_program_bytes';
import Appointments from './coaching/components/user_record/appointments';
import CoachVideoSessionPage from './coaching/pages/coach_video_session_page';
import Reflections from './coaching/components/user_record/reflections';
import CustomNotifications from './coaching/components/user_record/custom_notifications';

export default function Router() {
  const [authValidated, loggedIn] = useAuthValidations();

  const loggedInAppRoutes = (
    <Route path="/">
      <Route path="coaching" element={<CoachingPageContainer />}>
        <Route index element={<CoachingDashboardPage />} />
        <Route path="assignment" element={<CoachingAssignmentPage />} />
        <Route path="video-session" element={<CoachVideoSessionPage />} />
        <Route path="user/:userId" element={<UserRecordPage />}>
          <Route path="appointments" element={<Appointments />} />
          <Route path="completed_bytes" element={<CompletedBytes />} />
          <Route
            path="custom_notifications"
            element={<CustomNotifications />}
          />
          <Route path="users_program_bytes" element={<UsersProgramBytes />} />
          <Route path="care_team" element={<CareTeam />} />
          <Route path="ewb_quizzes" element={<EwbQuizzes />} />
          <Route path="demographics" element={<DemographicsAssessments />} />
          <Route path="dass_assessments" element={<DassAssessments />} />
          <Route path="checkins" element={<Checkins />} />
          <Route path="intentions" element={<Intentions />} />
          <Route path="memos" element={<Memos />} />
          <Route path="messages" element={<CoachingUserMessageThreads />} />
          <Route path="threads/:threadId" element={<ThreadDetails />} />
          <Route path="payments" element={<Payments />} />
          <Route path="recommended_content" element={<RecommendedContent />} />
          <Route path="reflections" element={<Reflections />} />
          <Route path="session_notes" element={<SessionNotes />} />
          <Route index element={<Navigate to="messages" />} />
        </Route>
      </Route>

      <Route path="cms" element={<CmsPageContainer />}>
        <Route index element={<Navigate to="bytes" replace />} />
        <Route path="bytes" element={<BytesPage />} />
        <Route path="bytes/:byteId" element={<ByteEditPage />} />
        <Route path="explore-categories" element={<ExploreCategoriesPage />} />
        <Route
          path="explore-categories/:domainId"
          element={<ExploreCategoryGroupingsPage />}
        />
        <Route
          path="domains"
          element={<Navigate to="/cms/explore-categories" />}
        />
      </Route>

      <Route path="internal" element={<InternalPageContainer />}>
        <Route path="chat_bots" element={<ChatBotsPage />} />
        <Route path="chat_bot_threads" element={<ChatBotThreadsPage />} />
        <Route
          path="chat_bot_threads/:threadId"
          element={<ChatBotThreadPage />}
        />

        <Route path="partners" element={<PartnersPage />} />
        <Route path="users/delete" element={<UserDeletionPage />} />
      </Route>

      <Route index element={<Navigate to={'coaching'} />} />
    </Route>
  );

  const loggedOutRoutes = (
    <Route>
      <Route path="/" element={<LoginPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
  );

  if (!authValidated) {
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        {authValidated && loggedIn ? loggedInAppRoutes : loggedOutRoutes}
      </Routes>
    </BrowserRouter>
  );
}

const useAuthValidations = () => {
  const [authValidated, setAuthValidated] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

    setAuthValidated(true);

    if (!authToken) {
      setLoggedIn(false);
      return;
    }

    setAuthTokenForAllRequests(authToken);
    setLoggedIn(true);
  }, []);

  return [authValidated, loggedIn];
};
